@import "../Vars.scss";

.barra {
    background-color: $orange-saniver !important;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

a, p {
    color: white;
}

h3 {
    color: $orange-saniver;
}

.logo {
    height: 35px;
    margin-left: 20px;
    margin-top: 5px;
}

.user-menu {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 50%;
    margin-top: 6px;
    margin-right: 1rem;
    outline: solid 0px #2F8CCA;
    transition: outline 0.1s ease-in;
}

.user-menu:hover {
    outline-width: 4px;
}

.user-initials {
    font-size: 25px;
}
