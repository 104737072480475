.tuple-non{
    background-color: rgb(255, 255, 255);
}


.tuple-par{
    background-color:rgb(252, 252, 252);
}

.checkbox{
    padding: 10px;
}

.table-td {
    padding: 20px;
}

.tr-table:hover {
    background-color: #EEEEEE;
}

.tr-table {
    border-top: 1px solid #9e9e9e48;
}
