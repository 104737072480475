.header {
    box-shadow: 0px 1px 3px 0px #F5F5F5;
    border-radius: 20px 20px 0 0;
}

table {
    position: relative;

}

th {
    padding: 20px;
    font-weight:700;
    color:#606F89;
    background-color: #EEEEEE;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
}

th:first-child {
    border-radius: 20px 0 0 0 ;
}

th:last-child {
    border-radius: 0 20px 0 0 ;
}