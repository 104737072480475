// Aquí van los estilos de CSS necesarios para la maquetación de la sección Main
// .col,
// .row {
// 	border: solid red 1px;
// }

.main {
	max-width: calc(100vw - 315px);
	width: 100%;
}

.section-1 {
	width: 100%;
	padding: 0;
}

.section-3 {
	max-width: 300px;
}

// Fer CSS
#section1 {
	// border: 1px solid blue;
	height: 350px;
}

#section2 {
    margin-top: 55px;
    // border: 1px solid green;
	height: 115px;
}

#offices {
	width: 170px;
	margin-bottom: 15px;
}

#title-summary {
    margin-bottom: 35px;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1.9fr 1.3fr 0.3fr 0.5fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    // border: 1px solid rgb(0, 255, 221);
}

.view-rows {
	grid-area: 2 / 1 / 7 / 5;
	// border: 1px solid green;
}

.view-row-1 {
	grid-area: 2 / 1 / 3 / 3;
	// border: 1px solid blue;
}

.view-row-2 {
	grid-area: 3 / 1 / 4 / 3;
	// border: 1px solid black;
}

.view-row-3 {
	grid-area: 4 / 1 / 5 / 3;
	// border: 1px solid green;
}

.view-row-4 {
	grid-area: 5 / 1 / 6 / 3;
	// border: 1px solid blue;
}

.view-row-5 {
	grid-area: 6 / 1 / 7 / 3;
	// border: 1px solid black;
}

.labels {
	grid-area: 1 / 1 / 2 / 9;
	// border: 1px solid red;
	padding-top: 5px;
}

.button {
	grid-area: 2 / 5 / 3 / 9;
	// border: 1px solid purple;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.graph {
	grid-area: 3 / 5 / 7 / 9;
	// border: 1px solid orange;
}

.btn-1 {
	grid-area: 2 / 3 / 3 / 5;
	// border: 1px solid blue;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-2 {
	grid-area: 3 / 3 / 4 / 5;
	// border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-3 {
	grid-area: 4 / 3 / 5 / 5;
	// border: 1px solid green;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-4 {
	grid-area: 5 / 3 / 6 / 5;
	// border: 1px solid blue;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-5 {
	grid-area: 6 / 3 / 7 / 5;
	// border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
	grid-area: 1 / 5 / 2 / 9;
    // border: 1px solid orange;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

#view-5,
#view-4,
#view-3,
#view-2,
#view-1 {
    margin-bottom: -10px;
}

#view-graph-1,
#view-graph-2,
#view-graph-3,
#view-graph-4,
#view-graph-5 {
    color: black;
}

// Section 2

#section2 {
	display: flex;
	justify-content: center;
	padding-top: 7px;
}

.grid-container-section-2 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: "minuta minuta qr qr calendario calendario";
}

.minuta,
.qr,
.calendario {
	width: 110px;
	height: 110px;
	display: block;
	padding-top: 20px;
	border-radius: 25px;
	border: 1px solid #79767a;
	-webkit-box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.75);
	box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.75);
	transition: transform .2s;
}

.minuta:hover,
.qr:hover,
.calendario:hover {
	-ms-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	transform: scale(1.1); 
}

.minuta {
	grid-area: minuta;
}
  
.qr {
	grid-area: qr;
	margin-right: 20px;
}
  
.calendario {
	grid-area: calendario;
	margin-right: 20px;
}

#button-icon {
	display: flex;
	justify-content: center;
}

#button-text {
	padding-top: 10px;
	display: flex;
	justify-content: center;
}

#icon {
	font-size: 25px;
}

.link-button {
	text-decoration: none;
	color: rgba(0, 0, 0, 0.479);
}

.link-button:hover {
	color: black;
}