
@import "../Vars.scss";

.menu2{
  height: 100%;
  background-color: #79767a;
  a{
    color: white;
  }

  .selected{
    background-color: #fff;
    a{
      color: #F58B00;
    }
    span {
      color: $orange-saniver;
    }
    .icon{
      fill: #F58B00 ;
    }
  }
  .menu-element:hover{
    color: #F58B00;
    background-color: #fff;
    a{
      color: #F58B00;
      text-decoration: none;
      span{
        text-decoration: none;
      }
    }
    .icon{
      fill: #F58B00 ;
    }
  }
}

