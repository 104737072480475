.btnDelete{
    width: 30px;
    height: 30px;
    background: none;
    border-radius: 10px;
    border: 2px solid transparent;
}

.btnDelete:hover{
    width: 30px;
    height: 30px;
    background: none;
    border-radius: 10px;
    border: 2px solid #fda89b9f;
    background-color:#fda89b9f;
}

.btnDelete:active{
    width: 30px;
    height: 30px;
    background: none;
    border-radius: 10px;
    border: 2px solid #ff9f91;
    background-color:#ff9f91;
}

