.btnEdit {
    width: 30px;
    height: 30px;
    background: none;
    border-radius: 10px;
    border: 2px solid transparent;
}

.btnEdit:hover {
    width: 30px;
    height: 30px;
    background: none;
    border-radius: 10px;
    border: 2px solid #dadada;
    background-color:#dadada;
}

.btnEdit:active {
    width: 30px;
    height: 30px;
    background: none;
    border-radius: 10px;
    border: 2px solid #c5c5c5;
    background-color:#c5c5c5;
}

